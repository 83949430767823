/* eslint-disable */
import * as Types from './types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from './hooks';
const defaultOptions = {} as const;
export const CompanyShortFragmentDoc = gql`
    fragment CompanyShort on Company {
  id
  short
  logo {
    thumbnailUrl
    alt
  }
  status
  url
}
    `;
export const CompanyWithContactsFragmentDoc = gql`
    fragment CompanyWithContacts on Company {
  id
  short
  title
  logo {
    thumbnailUrl
    alt
  }
  inn
  ogrn
  address
  phone
  email
  status
  url
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on PageInfo {
  hasPreviousPage
  hasNextPage
  startCursor
  endCursor
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on Profile {
  name
  email
  phone
  isAddressSet
  isEmailActive
  isNeedUpdate
}
    `;
export const UserShortFragmentDoc = gql`
    fragment UserShort on User {
  id
  name
  avatar {
    thumbnailUrl
    alt
  }
  company
  status
  url
}
    `;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  level
  text
  publishedAt
  publishedBy {
    ...UserShort
  }
  likesCount
  isLiked
}
    ${UserShortFragmentDoc}`;
export const CommentErrorFragmentDoc = gql`
    fragment CommentError on CommentError {
  code
  field
  message
}
    `;
export const MainArticleFragmentDoc = gql`
    fragment MainArticle on Article {
  id
  title
  tizer
  image {
    previewUrl
    alt
  }
  rubrica {
    groupId
    groupName
  }
  url
}
    `;
export const MainNewsFragmentDoc = gql`
    fragment MainNews on News {
  id
  publishedAt
  title
  tizer
  image {
    previewUrl
    alt
  }
  rubrica {
    short
  }
  url
}
    `;
export const MagazineSubscribeDocument = gql`
    mutation magazineSubscribe($email: String!, $name: String!) {
  subscriptionCreate(input: {email: $email, name: $name, scopes: MAGAZINE}) {
    subscription {
      id
      isSubscribed
    }
    errors {
      code
      field
      message
    }
  }
}
    `;
export type MagazineSubscribeMutationFn = Apollo.MutationFunction<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>;

/**
 * __useMagazineSubscribeMutation__
 *
 * To run a mutation, you first call `useMagazineSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagazineSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magazineSubscribeMutation, { data, loading, error }] = useMagazineSubscribeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useMagazineSubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>(MagazineSubscribeDocument, options);
      }
export type MagazineSubscribeMutationHookResult = ReturnType<typeof useMagazineSubscribeMutation>;
export type MagazineSubscribeMutationResult = Apollo.MutationResult<Types.MagazineSubscribeMutation>;
export type MagazineSubscribeMutationOptions = Apollo.BaseMutationOptions<Types.MagazineSubscribeMutation, Types.MagazineSubscribeMutationVariables>;
export const CommentCreateDocument = gql`
    mutation CommentCreate($id: ID!, $input: CommentCreateInput!) {
  commentCreate(id: $id, input: $input) {
    comment {
      ...Comment
    }
    errors {
      ...CommentError
    }
  }
}
    ${CommentFragmentDoc}
${CommentErrorFragmentDoc}`;
export type CommentCreateMutationFn = Apollo.MutationFunction<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>;

/**
 * __useCommentCreateMutation__
 *
 * To run a mutation, you first call `useCommentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentCreateMutation, { data, loading, error }] = useCommentCreateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>(CommentCreateDocument, options);
      }
export type CommentCreateMutationHookResult = ReturnType<typeof useCommentCreateMutation>;
export type CommentCreateMutationResult = Apollo.MutationResult<Types.CommentCreateMutation>;
export type CommentCreateMutationOptions = Apollo.BaseMutationOptions<Types.CommentCreateMutation, Types.CommentCreateMutationVariables>;
export const CommentDeleteDocument = gql`
    mutation CommentDelete($id: ID!) {
  commentDelete(id: $id) {
    errors {
      ...CommentError
    }
  }
}
    ${CommentErrorFragmentDoc}`;
export type CommentDeleteMutationFn = Apollo.MutationFunction<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>;

/**
 * __useCommentDeleteMutation__
 *
 * To run a mutation, you first call `useCommentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentDeleteMutation, { data, loading, error }] = useCommentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>(CommentDeleteDocument, options);
      }
export type CommentDeleteMutationHookResult = ReturnType<typeof useCommentDeleteMutation>;
export type CommentDeleteMutationResult = Apollo.MutationResult<Types.CommentDeleteMutation>;
export type CommentDeleteMutationOptions = Apollo.BaseMutationOptions<Types.CommentDeleteMutation, Types.CommentDeleteMutationVariables>;
export const CommentLikeDocument = gql`
    mutation CommentLike($id: ID!) {
  commentLike(id: $id) {
    errors {
      ...CommentError
    }
  }
}
    ${CommentErrorFragmentDoc}`;
export type CommentLikeMutationFn = Apollo.MutationFunction<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>;

/**
 * __useCommentLikeMutation__
 *
 * To run a mutation, you first call `useCommentLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentLikeMutation, { data, loading, error }] = useCommentLikeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentLikeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>(CommentLikeDocument, options);
      }
export type CommentLikeMutationHookResult = ReturnType<typeof useCommentLikeMutation>;
export type CommentLikeMutationResult = Apollo.MutationResult<Types.CommentLikeMutation>;
export type CommentLikeMutationOptions = Apollo.BaseMutationOptions<Types.CommentLikeMutation, Types.CommentLikeMutationVariables>;
export const CommentLikeDeleteDocument = gql`
    mutation CommentLikeDelete($id: ID!) {
  commentLikeDelete(id: $id) {
    errors {
      ...CommentError
    }
  }
}
    ${CommentErrorFragmentDoc}`;
export type CommentLikeDeleteMutationFn = Apollo.MutationFunction<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>;

/**
 * __useCommentLikeDeleteMutation__
 *
 * To run a mutation, you first call `useCommentLikeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentLikeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentLikeDeleteMutation, { data, loading, error }] = useCommentLikeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentLikeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>(CommentLikeDeleteDocument, options);
      }
export type CommentLikeDeleteMutationHookResult = ReturnType<typeof useCommentLikeDeleteMutation>;
export type CommentLikeDeleteMutationResult = Apollo.MutationResult<Types.CommentLikeDeleteMutation>;
export type CommentLikeDeleteMutationOptions = Apollo.BaseMutationOptions<Types.CommentLikeDeleteMutation, Types.CommentLikeDeleteMutationVariables>;
export const GetCommentsDocument = gql`
    query GetComments($id: ID!, $first: Int = 100) {
  me {
    id
    name
    avatar {
      previewUrl
      alt
    }
  }
  comments(id: $id, first: $first) {
    totalCount
    edges {
      node {
        ...Comment
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${CommentFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables> & ({ variables: Types.GetCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export function useGetCommentsSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsSuspenseQueryHookResult = ReturnType<typeof useGetCommentsSuspenseQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<Types.GetCommentsQuery, Types.GetCommentsQueryVariables>;
export const MagazineSubscriptionCreateDocument = gql`
    mutation MagazineSubscriptionCreate($input: MagazineSubscriptionCreateInput!) {
  magazineSubscriptionCreate(input: $input) {
    errors {
      code
      field
      message
    }
  }
}
    `;
export type MagazineSubscriptionCreateMutationFn = Apollo.MutationFunction<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>;

/**
 * __useMagazineSubscriptionCreateMutation__
 *
 * To run a mutation, you first call `useMagazineSubscriptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMagazineSubscriptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [magazineSubscriptionCreateMutation, { data, loading, error }] = useMagazineSubscriptionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMagazineSubscriptionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>(MagazineSubscriptionCreateDocument, options);
      }
export type MagazineSubscriptionCreateMutationHookResult = ReturnType<typeof useMagazineSubscriptionCreateMutation>;
export type MagazineSubscriptionCreateMutationResult = Apollo.MutationResult<Types.MagazineSubscriptionCreateMutation>;
export type MagazineSubscriptionCreateMutationOptions = Apollo.BaseMutationOptions<Types.MagazineSubscriptionCreateMutation, Types.MagazineSubscriptionCreateMutationVariables>;
export const MainArticlesMoreDocument = gql`
    query MainArticlesMore($after: String, $first: Int) {
  articles(after: $after, first: $first, filter: {publishMagazine: false}) {
    edges {
      node {
        ...MainArticle
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${MainArticleFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useMainArticlesMoreQuery__
 *
 * To run a query within a React component, call `useMainArticlesMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainArticlesMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainArticlesMoreQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMainArticlesMoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>(MainArticlesMoreDocument, options);
      }
export function useMainArticlesMoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>(MainArticlesMoreDocument, options);
        }
export function useMainArticlesMoreSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>(MainArticlesMoreDocument, options);
        }
export type MainArticlesMoreQueryHookResult = ReturnType<typeof useMainArticlesMoreQuery>;
export type MainArticlesMoreLazyQueryHookResult = ReturnType<typeof useMainArticlesMoreLazyQuery>;
export type MainArticlesMoreSuspenseQueryHookResult = ReturnType<typeof useMainArticlesMoreSuspenseQuery>;
export type MainArticlesMoreQueryResult = Apollo.QueryResult<Types.MainArticlesMoreQuery, Types.MainArticlesMoreQueryVariables>;
export const MainNewsMoreDocument = gql`
    query MainNewsMore($after: String, $first: Int) {
  news(after: $after, first: $first) {
    edges {
      node {
        ...MainNews
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${MainNewsFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useMainNewsMoreQuery__
 *
 * To run a query within a React component, call `useMainNewsMoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainNewsMoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainNewsMoreQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useMainNewsMoreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>(MainNewsMoreDocument, options);
      }
export function useMainNewsMoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>(MainNewsMoreDocument, options);
        }
export function useMainNewsMoreSuspenseQuery(baseOptions?: ApolloReactHooks.SkipToken | ApolloReactHooks.SuspenseQueryHookOptions<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>) {
          const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>(MainNewsMoreDocument, options);
        }
export type MainNewsMoreQueryHookResult = ReturnType<typeof useMainNewsMoreQuery>;
export type MainNewsMoreLazyQueryHookResult = ReturnType<typeof useMainNewsMoreLazyQuery>;
export type MainNewsMoreSuspenseQueryHookResult = ReturnType<typeof useMainNewsMoreSuspenseQuery>;
export type MainNewsMoreQueryResult = Apollo.QueryResult<Types.MainNewsMoreQuery, Types.MainNewsMoreQueryVariables>;
export const SmartlightExpertAdviceDocument = gql`
    mutation smartlightExpertAdvice($email: String!, $name: String!, $question: String!, $company: SmartlightCompanyEnum!) {
  smartlightExpertAdviceCreate(
    input: {email: $email, name: $name, question: $question, company: $company}
  ) {
    expertAdvice {
      id
      created
      email
      name
      question
    }
    errors {
      code
      field
      message
    }
  }
}
    `;
export type SmartlightExpertAdviceMutationFn = Apollo.MutationFunction<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>;

/**
 * __useSmartlightExpertAdviceMutation__
 *
 * To run a mutation, you first call `useSmartlightExpertAdviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmartlightExpertAdviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smartlightExpertAdviceMutation, { data, loading, error }] = useSmartlightExpertAdviceMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      question: // value for 'question'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useSmartlightExpertAdviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>(SmartlightExpertAdviceDocument, options);
      }
export type SmartlightExpertAdviceMutationHookResult = ReturnType<typeof useSmartlightExpertAdviceMutation>;
export type SmartlightExpertAdviceMutationResult = Apollo.MutationResult<Types.SmartlightExpertAdviceMutation>;
export type SmartlightExpertAdviceMutationOptions = Apollo.BaseMutationOptions<Types.SmartlightExpertAdviceMutation, Types.SmartlightExpertAdviceMutationVariables>;