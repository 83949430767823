import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import introspectionQueryResultData from './fragmentTypes.generated';
import { TypedTypePolicies } from './typePolicies.generated';

const link = createHttpLink({
    uri: GRAPHQL_API_ENDPOINT,
    credentials: 'include'
});

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        possibleTypes: introspectionQueryResultData.possibleTypes,
        typePolicies: {} as TypedTypePolicies
    }),
    link
});

export default apolloClient;
