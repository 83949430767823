import {
    MutationHookOptions as BaseMutationHookOptions,
    DocumentNode,
    MutationFunction,
    MutationResult,
    useMutation as useBaseMutation
} from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { NoInfer } from '@apollo/client/react/types/types';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

export type MutationResultWithOpts<TData> = MutationResult<TData>;

export type UseMutation<TData, TVariables extends OperationVariables> = [
    MutationFunction<TData, TVariables>,
    MutationResultWithOpts<TData>,
];
export type UseMutationHook<TData, TVariables extends OperationVariables> = (
    cbs: MutationHookOptions<TData, TVariables>
) => UseMutation<TData, TVariables>;
export type MutationHookOptions<TData, TVariables extends OperationVariables> = BaseMutationHookOptions<TData, TVariables>;

export function useMutation<TData, TVariables extends OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
    {
        onCompleted,
        onError,
        ...opts
    }: MutationHookOptions<TData, TVariables>
): UseMutation<NoInfer<TData>, NoInfer<TVariables>> {

    const [mutateFn, result] = useBaseMutation(mutation, {
        ...opts,
        onCompleted: data => {
            if (onCompleted) {
                onCompleted(data);
            }
        },
        onError: error => {
            if (onError) {
                onError(error);
            }
        }
    });

    return [mutateFn, { ...result }];
}

function makeMutation<TData, TVariables extends OperationVariables>(
    mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
): UseMutationHook<TData, TVariables> {
    return (opts: MutationHookOptions<TData, TVariables>) =>
        useMutation<TData, TVariables>(mutation, opts);
}

export default makeMutation;
