/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  PositiveDecimal: { input: any; output: any; }
};

export enum AccountErrorCode {
  ACTIVATE_OWN_ACCOUNT = 'ACTIVATE_OWN_ACCOUNT',
  ACTIVATE_SUPERUSER_ACCOUNT = 'ACTIVATE_SUPERUSER_ACCOUNT',
  DEACTIVATE_OWN_ACCOUNT = 'DEACTIVATE_OWN_ACCOUNT',
  DEACTIVATE_SUPERUSER_ACCOUNT = 'DEACTIVATE_SUPERUSER_ACCOUNT',
  DELETE_NON_STAFF_USER = 'DELETE_NON_STAFF_USER',
  DELETE_OWN_ACCOUNT = 'DELETE_OWN_ACCOUNT',
  DELETE_STAFF_ACCOUNT = 'DELETE_STAFF_ACCOUNT',
  DELETE_SUPERUSER_ACCOUNT = 'DELETE_SUPERUSER_ACCOUNT',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INACTIVE = 'INACTIVE',
  INVALID = 'INVALID',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  JWT_DECODE_ERROR = 'JWT_DECODE_ERROR',
  JWT_INVALID_CSRF_TOKEN = 'JWT_INVALID_CSRF_TOKEN',
  JWT_INVALID_TOKEN = 'JWT_INVALID_TOKEN',
  JWT_MISSING_TOKEN = 'JWT_MISSING_TOKEN',
  JWT_SIGNATURE_EXPIRED = 'JWT_SIGNATURE_EXPIRED',
  LEFT_NOT_MANAGEABLE_PERMISSION = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  NOT_FOUND = 'NOT_FOUND',
  OUT_OF_SCOPE_GROUP = 'OUT_OF_SCOPE_GROUP',
  OUT_OF_SCOPE_PERMISSION = 'OUT_OF_SCOPE_PERMISSION',
  OUT_OF_SCOPE_USER = 'OUT_OF_SCOPE_USER',
  PASSWORD_ENTIRELY_NUMERIC = 'PASSWORD_ENTIRELY_NUMERIC',
  PASSWORD_RESET_ALREADY_REQUESTED = 'PASSWORD_RESET_ALREADY_REQUESTED',
  PASSWORD_TOO_COMMON = 'PASSWORD_TOO_COMMON',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_SIMILAR = 'PASSWORD_TOO_SIMILAR',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export type AccountRegisterInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressUpdateErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID'
}

export type AddressUpdateInput = {
  addressMeta: Scalars['JSONString']['input'];
};

export type ArticleFilterInput = {
  publishMagazine?: InputMaybe<Scalars['Boolean']['input']>;
  publishMarket?: InputMaybe<Scalars['Boolean']['input']>;
  publishTraining?: InputMaybe<Scalars['Boolean']['input']>;
  publishedDate?: InputMaybe<DateRangeInput>;
};

export enum ArticleSortField {
  PUBLISHED_DATE = 'PUBLISHED_DATE'
}

export type ArticleSortingInput = {
  /** Specifies the direction in which to sort articles. */
  direction: OrderDirection;
  /** Sort articles by the selected field. */
  field: ArticleSortField;
};

export enum ClientTypeEnum {
  INDIVIDUALS = 'INDIVIDUALS',
  LEGAL = 'LEGAL'
}

export type CommentCreateInput = {
  parent?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export enum CommentErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export type CommentUpdateInput = {
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum CompanyStatusEnum {
  BUSINESS = 'BUSINESS',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
  FREE = 'FREE',
  INFO = 'INFO',
  PROMO = 'PROMO',
  VIP = 'VIP'
}

export type DateRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** End date. */
  lte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** End date. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum DeliveryMethodEnum {
  COURIER = 'COURIER',
  PICKUP = 'PICKUP',
  POST = 'POST',
  TRANSPORT = 'TRANSPORT'
}

export enum EmailConfirmRequestErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID'
}

export type EventFilterInput = {
  endDate?: InputMaybe<DateRangeInput>;
  startDate?: InputMaybe<DateRangeInput>;
};

export enum EventSortField {
  END_DATE = 'END_DATE',
  START_DATE = 'START_DATE'
}

export type EventSortingInput = {
  /** Specifies the direction in which to sort events. */
  direction: OrderDirection;
  /** Sort events by the selected field. */
  field: EventSortField;
};

export enum FavoriteErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED'
}

export type MagazineSubscriptionCreateInput = {
  appointment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  isAgree: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  variant?: InputMaybe<MagazineSubscriptionVariantEnum>;
};

export enum MagazineSubscriptionErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum MagazineSubscriptionVariantEnum {
  EMAIL = 'EMAIL',
  POST = 'POST'
}

export type NewsFilterInput = {
  publishFoton?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum NewsSortField {
  PUBLISHED_DATE = 'PUBLISHED_DATE'
}

export type NewsSortingInput = {
  /** Specifies the direction in which to sort news. */
  direction: OrderDirection;
  /** Sort news by the selected field. */
  field: NewsSortField;
};

export type OfferCategoryFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OfferClickFilterInput = {
  clickDate?: InputMaybe<DateTimeRangeInput>;
};

export enum OfferClickSortField {
  CLICK_DATE = 'CLICK_DATE',
  CLICK_PRICE = 'CLICK_PRICE'
}

export type OfferClickSortingInput = {
  /** Specifies the direction in which to sort clicks. */
  direction: OrderDirection;
  /** Sort clicks by the selected field. */
  field: OfferClickSortField;
};

export type OfferFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderCreateFromOfferInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  companyAddress?: InputMaybe<Scalars['String']['input']>;
  companyInn?: InputMaybe<Scalars['String']['input']>;
  companyOgrn?: InputMaybe<Scalars['String']['input']>;
  deliveryAddress?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod: DeliveryMethodEnum;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
  paymentMethod: PaymentMethodEnum;
  paymentRequisites?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type OrderCreateInput = {
  customerCompany?: InputMaybe<Scalars['String']['input']>;
  customerCompanyAddress?: InputMaybe<Scalars['String']['input']>;
  customerCompanyInn?: InputMaybe<Scalars['String']['input']>;
  customerCompanyOgrn?: InputMaybe<Scalars['String']['input']>;
  customerEmail: Scalars['String']['input'];
  customerName?: InputMaybe<Scalars['String']['input']>;
  customerPhone?: InputMaybe<Scalars['String']['input']>;
  deliveryAddress?: InputMaybe<Scalars['String']['input']>;
  deliveryMethod: DeliveryMethodEnum;
  items: Array<OrderItemCreateInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: PaymentMethodEnum;
  paymentRequisites?: InputMaybe<Scalars['String']['input']>;
  sellerId: Scalars['ID']['input'];
};

export enum OrderDirection {
  /** Specifies an ascending sort order. */
  ASC = 'ASC',
  /** Specifies a descending sort order. */
  DESC = 'DESC'
}

export enum OrderErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  OFFER_NOT_FOUND = 'OFFER_NOT_FOUND'
}

export type OrderItemCreateInput = {
  offerId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export enum OrderSettingsErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  REQUIRED = 'REQUIRED'
}

export type OrderSettingsUpdateInput = {
  client: Array<InputMaybe<ClientTypeEnum>>;
  delivery: Array<InputMaybe<DeliveryMethodEnum>>;
  deliveryMemo: Scalars['String']['input'];
  payment: Array<InputMaybe<PaymentMethodEnum>>;
  paymentMemo: Scalars['String']['input'];
};

export enum PaymentMethodEnum {
  CARD = 'CARD',
  CASH = 'CASH',
  CLEARING = 'CLEARING',
  COD = 'COD'
}

export enum PostSortField {
  PUBLISHED_AT = 'PUBLISHED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type PostSortingInput = {
  /** Specifies the direction in which to sort posts. */
  direction: OrderDirection;
  /** Sort posts by the selected field. */
  field: PostSortField;
};

export enum SmartlightCompanyEnum {
  ARLIGHT = 'ARLIGHT',
  OTHER = 'OTHER'
}

export type SmartlightExpertAdviceCreateInput = {
  company: SmartlightCompanyEnum;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  question: Scalars['String']['input'];
};

export enum SmartlightExpertAdviceErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export type SubscriptionCreateInput = {
  appointment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scopes: Array<SubscriptionScope>;
};

export enum SubscriptionErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum SubscriptionScope {
  ALL = 'ALL',
  DIGEST = 'DIGEST',
  EQUIPMENT = 'EQUIPMENT',
  EVENTS = 'EVENTS',
  FOTON = 'FOTON',
  MAGAZINE = 'MAGAZINE',
  MARKETING = 'MARKETING',
  PRESS_RELEASE = 'PRESS_RELEASE',
  SMARTLIGHT = 'SMARTLIGHT',
  TRAINING = 'TRAINING'
}

export enum SupportRequestErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  REQUIRED = 'REQUIRED'
}

export type SupportRequestInput = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatusEnum {
  BUSINESS = 'BUSINESS',
  DISABLED = 'DISABLED',
  FREE = 'FREE',
  INFO = 'INFO',
  PROMO = 'PROMO',
  VIP = 'VIP'
}

export enum VideoSortField {
  PUBLISHED_AT = 'PUBLISHED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type VideoSortingInput = {
  /** Specifies the direction in which to sort videos. */
  direction: OrderDirection;
  /** Sort videos by the selected field. */
  field: VideoSortField;
};

export type XmlPriceCreateInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  xml?: InputMaybe<Scalars['String']['input']>;
};

export enum XmlPriceErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export type XmlPriceFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum XmlPriceSortField {
  CATEGOGIES_COUNT = 'CATEGOGIES_COUNT',
  OFFERS_COUNT = 'OFFERS_COUNT',
  UPDATED_AT = 'UPDATED_AT'
}

export type XmlPriceSortingInput = {
  /** Specifies the direction in which to sort xmlprices. */
  direction: OrderDirection;
  /** Sort xmlprices by the selected field. */
  field: XmlPriceSortField;
};

export type XmlPriceUpdateInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  xml?: InputMaybe<Scalars['String']['input']>;
};

export type MagazineSubscribeMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type MagazineSubscribeMutation = { __typename: 'Mutation', subscriptionCreate: { __typename: 'SubscriptionCreate', subscription: { __typename: 'Subscription', id: string, isSubscribed: boolean } | null, errors: Array<{ __typename: 'SubscriptionError', code: SubscriptionErrorCode, field: string | null, message: string | null }> } | null };

export type CompanyShortFragment = { __typename: 'Company', id: string, short: string, status: CompanyStatusEnum | null, url: string, logo: { __typename: 'Image', thumbnailUrl: string, alt: string } | null };

export type CompanyWithContactsFragment = { __typename: 'Company', id: string, short: string, title: string, inn: string | null, ogrn: string | null, address: string | null, phone: string | null, email: string | null, status: CompanyStatusEnum | null, url: string, logo: { __typename: 'Image', thumbnailUrl: string, alt: string } | null };

export type PageInfoFragment = { __typename: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor: string | null, endCursor: string | null };

export type UserShortFragment = { __typename: 'User', id: string, name: string, company: string, status: UserStatusEnum, url: string, avatar: { __typename: 'Image', thumbnailUrl: string, alt: string } | null };

export type UserProfileFragment = { __typename: 'Profile', name: string, email: string, phone: string, isAddressSet: boolean, isEmailActive: boolean, isNeedUpdate: boolean };

export type CommentFragment = { __typename: 'Comment', id: string, level: number, text: string, publishedAt: any, likesCount: number, isLiked: boolean | null, publishedBy: { __typename: 'User', id: string, name: string, company: string, status: UserStatusEnum, url: string, avatar: { __typename: 'Image', thumbnailUrl: string, alt: string } | null } };

export type CommentErrorFragment = { __typename: 'CommentError', code: CommentErrorCode, field: string | null, message: string | null };

export type CommentCreateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: CommentCreateInput;
}>;


export type CommentCreateMutation = { __typename: 'Mutation', commentCreate: { __typename: 'CommentCreate', comment: { __typename: 'Comment', id: string, level: number, text: string, publishedAt: any, likesCount: number, isLiked: boolean | null, publishedBy: { __typename: 'User', id: string, name: string, company: string, status: UserStatusEnum, url: string, avatar: { __typename: 'Image', thumbnailUrl: string, alt: string } | null } } | null, errors: Array<{ __typename: 'CommentError', code: CommentErrorCode, field: string | null, message: string | null }> } | null };

export type CommentDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommentDeleteMutation = { __typename: 'Mutation', commentDelete: { __typename: 'CommentDelete', errors: Array<{ __typename: 'CommentError', code: CommentErrorCode, field: string | null, message: string | null }> } | null };

export type CommentLikeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommentLikeMutation = { __typename: 'Mutation', commentLike: { __typename: 'CommentLikeCreate', errors: Array<{ __typename: 'CommentError', code: CommentErrorCode, field: string | null, message: string | null }> } | null };

export type CommentLikeDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommentLikeDeleteMutation = { __typename: 'Mutation', commentLikeDelete: { __typename: 'CommentLikeDelete', errors: Array<{ __typename: 'CommentError', code: CommentErrorCode, field: string | null, message: string | null }> } | null };

export type GetCommentsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCommentsQuery = { __typename: 'Query', me: { __typename: 'User', id: string, name: string, avatar: { __typename: 'Image', previewUrl: string, alt: string } | null } | null, comments: { __typename: 'CommentCountableConnection', totalCount: number | null, edges: Array<{ __typename: 'CommentCountableEdge', node: { __typename: 'Comment', id: string, level: number, text: string, publishedAt: any, likesCount: number, isLiked: boolean | null, publishedBy: { __typename: 'User', id: string, name: string, company: string, status: UserStatusEnum, url: string, avatar: { __typename: 'Image', thumbnailUrl: string, alt: string } | null } } }>, pageInfo: { __typename: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor: string | null, endCursor: string | null } } | null };

export type MainArticleFragment = { __typename: 'Article', id: string, title: string, tizer: string, url: string, image: { __typename: 'Image', previewUrl: string, alt: string } | null, rubrica: { __typename: 'ArticleRubrica', groupId: number, groupName: string } | null };

export type MainNewsFragment = { __typename: 'News', id: string, publishedAt: any, title: string, tizer: string, url: string, image: { __typename: 'Image', previewUrl: string, alt: string } | null, rubrica: Array<{ __typename: 'NewsRubrica', short: string } | null> | null };

export type MagazineSubscriptionCreateMutationVariables = Exact<{
  input: MagazineSubscriptionCreateInput;
}>;


export type MagazineSubscriptionCreateMutation = { __typename: 'Mutation', magazineSubscriptionCreate: { __typename: 'MagazineSubscriptionCreate', errors: Array<{ __typename: 'MagazineSubscriptionError', code: MagazineSubscriptionErrorCode, field: string | null, message: string | null }> } | null };

export type MainArticlesMoreQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MainArticlesMoreQuery = { __typename: 'Query', articles: { __typename: 'ArticleCountableConnection', edges: Array<{ __typename: 'ArticleCountableEdge', node: { __typename: 'Article', id: string, title: string, tizer: string, url: string, image: { __typename: 'Image', previewUrl: string, alt: string } | null, rubrica: { __typename: 'ArticleRubrica', groupId: number, groupName: string } | null } }>, pageInfo: { __typename: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor: string | null, endCursor: string | null } } | null };

export type MainNewsMoreQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type MainNewsMoreQuery = { __typename: 'Query', news: { __typename: 'NewsCountableConnection', edges: Array<{ __typename: 'NewsCountableEdge', node: { __typename: 'News', id: string, publishedAt: any, title: string, tizer: string, url: string, image: { __typename: 'Image', previewUrl: string, alt: string } | null, rubrica: Array<{ __typename: 'NewsRubrica', short: string } | null> | null } }>, pageInfo: { __typename: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, startCursor: string | null, endCursor: string | null } } | null };

export type SmartlightExpertAdviceMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  question: Scalars['String']['input'];
  company: SmartlightCompanyEnum;
}>;


export type SmartlightExpertAdviceMutation = { __typename: 'Mutation', smartlightExpertAdviceCreate: { __typename: 'SmartlightExpertAdviceCreate', expertAdvice: { __typename: 'SmartlightExpertAdvice', id: string, created: any, email: string, name: string, question: string } | null, errors: Array<{ __typename: 'SmartlightExpertAdviceError', code: SmartlightExpertAdviceErrorCode, field: string | null, message: string | null }> } | null };
