import { useContext } from 'react';

import { AppStateContext, AppStateContextType } from '../containers/AppState';

function useAppState(): AppStateContextType {
    const context = useContext(AppStateContext);
    if (context === undefined) {
        throw new Error('useAppState must be used within a AppStateContext');
    }
    return context;
}

export default useAppState;
